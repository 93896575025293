import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { IndicarImovelComponent } from './indicar-imovel/indicar-imovel.component';
// import { NossaAlmaComponent } from './nossa-alma/nossa-alma.component';
// import { HeaderComponent } from './core/components/header/header.component';
// import { FooterComponent } from './core/components/footer/footer.component';
// import {SharedModule} from "./core/shared.module";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
