import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'corretores',
    loadChildren: () => import('./corretores/corretores.module').then(m => m.CorretoresModule)
  },
  {
    path: 'proprietarios',
    loadChildren: () => import('./proprietarios/proprietarios.module').then(m => m.ProprietariosModule)
  },
  {
    path: 'indicar-imovel',
    loadChildren: () => import('./indicar-imovel/indicar-imovel.module').then(m => m.IndicarImovelModule)
  },
  {
    path: 'nossa-alma',
    loadChildren: () => import('./nossa-alma/nossa-alma.module').then(m => m.NossaAlmaModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
